import {
  addToCartPromo, clearCart,
  clearOrderPricesPromocode,
  clearPromotion,
  setOrderDeliveryMethod,
} from '../../actions';
import { createReducer, forAction } from '../../utils';

const initialState = {};

const addToCartPromoHandler = (state, { payload: data }) => data;

export default createReducer(
  [
    forAction(addToCartPromo, addToCartPromoHandler),
  ],
  initialState,
  [clearCart, clearOrderPricesPromocode, setOrderDeliveryMethod, clearPromotion],
);
