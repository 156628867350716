import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';

const namespace = 'ORDER';

export const setOrderCityMethod = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'CITY']));
export const setOrderDeliveryAdress = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'ADRESS']));
export const setOrderDeliveryMethod = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'METHOD']));
export const setOrderDeliveryTimeMethod = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'TIME_METHOD']));
export const setOrderDeliveryTime = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'TIME']));
export const setOrderDeliveryPoint = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'POINT']));
export const setOrderDeliveryZone = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'ZONE']));
export const setOrderDeliveryZoneData = createAction(makeActionType(namespace, ['SET', 'DELIVERY', 'ZONE', 'DATA']));

export const setOrderMessage = createAction(makeActionType(namespace, ['SET', 'MESSAGE']));
export const setOrderStep = createAction(makeActionType(namespace, ['SET', 'STEP']));
export const setOrderErrors = createAction(makeActionType(namespace, ['SET', 'ERRORS']));

export const setOrderUserName = createAction(makeActionType(namespace, ['SET', 'USER', 'NAME']));
export const setOrderPhone = createAction(makeActionType(namespace, ['SET', 'USER', 'PHONE']));
export const setOrderUnit = createAction(makeActionType(namespace, ['SET', 'USER', 'UNIT']));

export const cancelOrder = createAction(makeActionType(namespace, ['CANCEL']));

export const addAdressAction = createAction(makeActionType(namespace, ['ADD', 'ADRESS']));

export const setOrderPricesBonus = createAction(makeActionType(namespace, ['SET', 'PRICES', 'BONUS']));

export const setOrderPricesPromocode = createAction(makeActionType(namespace, ['SET', 'PRICES', 'PROMOCODE']));
export const setOrderPricesPromocodeData = createAction(makeActionType(namespace, ['SET', 'PRICES', 'PROMOCODE', 'DATA']));
export const fetchOrderPricesPromocode = createAction(makeActionType(namespace, ['FETCH', 'PRICES', 'PROMOCODE']));
export const clearOrderPricesPromocode = createAction(makeActionType(namespace, ['CLEAR', 'PRICES', 'PROMOCODE']));

export const setOrderPaymentMethod = createAction(makeActionType(namespace, ['SET', 'PAYMENT', 'METHOD']));
export const setOrderPaymentCash = createAction(makeActionType(namespace, ['SET', 'PAYMENT', 'CASH']));
export const setOrderPaymentEmail = createAction(makeActionType(namespace, ['SET', 'PAYMENT', 'EMAIL']));

export const createOrder = createAction(makeActionType(namespace, ['CREATE']));

export const setPromotions = createAction(makeActionType(namespace, ['SET', 'PROMOTIONS', 'LIST']));
export const setPromotion = createAction(makeActionType(namespace, ['SET', 'PROMOTIONS', 'DETAIL']));
export const fetchPromotions = createAction(makeActionType(namespace, ['FETCH', 'PROMOTIONS']));
export const clearPromotion = createAction(makeActionType(namespace, ['FETCH', 'PROMOTIONS', 'CLEAR']));
