import { createAction } from 'redux-actions';
import { makeActionType } from '../utils';

const namespace = 'CART';

export const addToCart = createAction(makeActionType(namespace, ['ADD', 'GOOD']));
export const addToCartPromo = createAction(makeActionType(namespace, ['ADD', 'GOOD', 'PROMO']));
export const deleteFromCart = createAction(makeActionType(namespace, ['DELETE', 'GOOD']));
export const deleteAllFromCart = createAction(makeActionType(namespace, ['DELETE', 'GOOD', 'ALL']));
export const addGiftToCart = createAction(makeActionType(namespace, ['ADD', 'GIFT']));
export const deleteGiftFromCart = createAction(makeActionType(namespace, ['DELETE', 'GIFT']));
export const addWokToCart = createAction(makeActionType(namespace, ['ADD', 'WOK']));
export const deleteWokFromCart = createAction(makeActionType(namespace, ['DELETE', 'WOK']));
export const openCart = createAction(makeActionType(namespace, ['OPEN', 'MODAL']));
export const closeCart = createAction(makeActionType(namespace, ['CLOSE', 'MODAL']));
export const clearCart = createAction(makeActionType(namespace, ['CLEAR']));
export const setCanCloseCart = createAction(makeActionType(namespace, ['SET', 'CAN_CLOSE']));
