import { cartSelector } from './root';
import { propNameSelector } from '../utils';

export const cartListSelector = propNameSelector(cartSelector, 'list');
export const wokListCartSelector = propNameSelector(cartSelector, 'wokList');
export const activeCartSelector = propNameSelector(cartSelector, 'active');
export const canCloseCartSelector = propNameSelector(cartSelector, 'canClose');
export const giftCartSelector = propNameSelector(cartSelector, 'gift');
export const goodCartListSelector = (id) => propNameSelector(cartListSelector, id);
export const dateCartSelector = propNameSelector(cartSelector, 'date');
export const promoGoodsCartSelector = propNameSelector(cartSelector, 'promo');
