import React from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import { GoodItem } from './GoodItem';
import { GoodsPrice } from './GoodsPrice';
import { fromStore } from '../../../../selectors';
import { WokItem } from './WokItem';

export function GoodsList() {
  const goods = useSelector(fromStore.cartGoodsSelector);
  const woks = useSelector(fromStore.wokListCartSelector);
  const gift = useSelector(fromStore.giftCartSelector);
  const promo = useSelector(fromStore.promoGoodsSelector);

  return (
    <div className="table-responsive mb-10">
      <table className="table align-middle table-row-dashed fs-6 gy-2 mb-0">
        <thead>
          <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
            <th>Товар</th>
            <th className="min-w-40px text-end"> </th>
            <th className="min-w-60px text-end">Цена</th>
            <th className="min-w-80px text-end">Всего</th>
          </tr>
        </thead>
        <tbody className="fw-bold text-gray-800">
          {Object.values(woks).map((item) => (
            <WokItem
              key={item.id}
              item={item}
            />
          ))}
          {goods.map((good) => (
            <GoodItem
              key={good.id}
              good={good}
              count={good.count}
            />
          ))}
          {!isEmpty(promo) && promo.map((good) => (
            <GoodItem
              key={good.id}
              good={good}
              count={good.count}
              isPromo
            />
          ))}
          {gift && <GoodItem gift key={`gift_${gift.id}`} good={gift} count={gift.count} />}
          <GoodsPrice />
        </tbody>
      </table>
    </div>
  );
}
