import { combineReducers } from 'redux';
import user from './user';
import login from './login';
import step from './step';
import error from './error';
import code from './code';
import authNumber from './authNumber';

export default combineReducers({
  user,
  login,
  step,
  error,
  code,
  authNumber,
});
