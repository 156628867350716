import {
  takeLatest, put, call, all,
  select,
} from 'redux-saga/effects';

import dayjs from 'dayjs';
import {
  initAction, setSettings, loaderAction, initSuccessAction,
  clearCart,
  fetchPromotions,
  setPromotions,
} from '../actions';
import { restUrls } from '../constants';
import { request } from '../utils';
import { fetchCurrentUser } from './auth';
import { fetchCitiesHandler } from './city';
import { onFetchGoods } from './goods';
import { fromStore } from '../selectors';

function* fetchSettingsHandler() {
  const cartDate = yield select(fromStore.dateCartSelector);
  const settingsData = yield call(request, { method: 'get', url: `${restUrls.settings}` });
  const settings = settingsData[0];
  yield put(setSettings(settings));

  if (settings.menuUpdated) {
    if (cartDate && dayjs(settings.menuUpdated).isAfter(dayjs(cartDate))) {
      yield put(clearCart());
    }
  }
}

function* fetchPromotionsHandler() {
  const promotions = yield call(request, { method: 'get', url: `${restUrls.promotions}`, params: { active: true, _limit: -1 } }, false);
  yield put(setPromotions(promotions));
}

function* initApp() {
  yield put(loaderAction({ field: 'global', value: true }));
  const result = yield all([
    fetchCurrentUser(),
    fetchCitiesHandler(),
    fetchSettingsHandler(),
    onFetchGoods(),
  ]);

  yield put(fetchPromotions());

  if (result.every((item) => item === undefined)) {
    yield put(initSuccessAction());
    yield put(loaderAction({ field: 'global', value: false }));
  }
}

export default [
  takeLatest(initAction, initApp),
  takeLatest(fetchPromotions, fetchPromotionsHandler),
];
