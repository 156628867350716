import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import {
  authUser, changeLogin, changeUserCode, setActiveModalAction,
} from '../../actions';
import { Modal } from '../../components';
import { fromStore, settingsSelector } from '../../selectors';
import { PHONE_REG_EXP } from '../../constants';
import { formatPhone, regexpPhone } from '../../utils';

const infoText = {
  sms: 'На указанный номер поступит СМС',
  phoneInside: 'На указанный номер поступит звонок, после чего введите последние 4 цифры входящего номера. В редких случаях звонок может быть помечен как спам. В случае, если звонок не поступил, код авторизации придет в виде смс',
  phoneOutside: 'Необходимо позвонить на бесплатный номер для авторизации',
  telegram: 'На указанный номер в Telegram поступит код авторизации',
};

const infoConfirmTitle = {
  sms: 'Код подтверждения отправлен',
  phoneInside: 'Ожидайте звонка',
  phoneOutside: 'Позвоните на бесплатный номер',
  telegram: 'Код подтверждения отправлен',
};

const infoConfirmText = {
  sms: 'Введите код подтверждения из СМС и нажмите войти',
  phoneInside: 'Введите 4 последних цифры входящего звонка и нажмите войти',
  phoneOutside: 'Позвоните c вашего номера на указанный номер, дождитесь ответа или сброса звонка и после нажмите кнопку войти ',
  telegram: 'Введите код подтверждения из сообщения в Telegram и нажмите войти',
};

function ConfirmStep({ mode }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.authLoaderSelector);
  const error = useSelector(fromStore.errorAuthSelector);
  const login = useSelector(fromStore.userLoginSelector);
  const authNumber = useSelector(fromStore.authNumberSelector);
  const initialValues = mode === 'sms' ? ['', '', '', '', ''] : ['', '', '', ''];
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    ref?.current?.childNodes[0].focus();
  }, [ref]);

  useEffect(() => {
    if (error) {
      setValues(initialValues);
      ref?.current?.childNodes[0].focus();
    }
  }, [error]);

  const onChangeValue = (index) => (e) => {
    const updatedValues = [...values];
    updatedValues[index] = e.target.value;
    setValues(updatedValues);

    const modeIndex = mode === 'sms' ? 4 : 3;

    if (index !== modeIndex && e.target.value) {
      ref.current.childNodes[index + 1].focus();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const code = values.join('');
    dispatch(authUser({
      login, code, mode, authNumber,
    }));
  };

  const renderError = () => {
    const title = error === 'AUTH-403' && mode === 'phoneOutside' ? 'Ваш номер пока не подтвержден' : 'Некорректный код подтверждения';
    const text = error === 'AUTH-403' && mode === 'phoneOutside' ? 'Попробуйте повторить операцию' : 'Введите корректный код подверждения';

    return (
      <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
            <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
            <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor" />
          </svg>
        </span>
        <div className="d-flex flex-column">
          <h4 className="mb-1 text-danger">{title}</h4>
          <span>{text}</span>
        </div>
      </div>
    );
  };

  const renderCodeInput = (
    <div className="mb-10 px-md-10">
      <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">Введите {mode === 'sms' ? '5-ти' : '4-x'} значный код</div>
      <div ref={ref} className="d-flex flex-wrap flex-stack">
        <input onChange={onChangeValue(0)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[0]} />
        <input onChange={onChangeValue(1)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[1]} />
        <input onChange={onChangeValue(2)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[2]} />
        <input onChange={onChangeValue(3)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[3]} />
        {mode === 'sms' && <input onChange={onChangeValue(4)} type="text" maxLength="1" className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-primary border-hover mx-1 my-2" value={values[4]} />}
      </div>
    </div>
  );

  const isDisabledButton = () => {
    if (loader) return true;
    if (mode === 'phoneOutside') return false;

    return values.some((item) => !item);
  };

  return (
    <form onSubmit={onSubmit} className="form w-100 mb-10">
      <div className="text-center mb-10">
        <img alt="Logo" className="mh-125px" src="/assets/media/svg/misc/smartphone.svg" />
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">{infoConfirmTitle[mode]}</h1>
        {authNumber && <h1 className="text-dark mb-10">{formatPhone(authNumber)}</h1>}
        <div className="fw-bold fs-4 mb-5">{infoConfirmText[mode]}</div>
        {mode !== 'phoneOutside' && (
          <div className="fw-bolder text-dark fs-3">
            +7******
            {regexpPhone(login).slice(-4)}
          </div>
        )}
      </div>
      {mode !== 'phoneOutside' && renderCodeInput}
      {error && renderError()}
      <div className="d-flex flex-center">
        <button disabled={isDisabledButton()} type="submit" className="btn btn-lg btn-danger fw-bolder">
          {loader ? (
            <span>
              В процессе...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (<span className="indicator-label">Войти</span>)}
        </button>
      </div>
    </form>
  );
}

ConfirmStep.defaultProps = {
  mode: '',
};

ConfirmStep.propTypes = {
  mode: PropTypes.string,
};

const authTitles = {
  sms: 'СМС-авторизация',
  phoneInside: 'Через входящий звонок',
  phoneOutside: 'Звонок на бесплатный номер',
  telegram: 'Через Telegram',
};

const getAuth = (items) => {
  if (!items) return [];
  return items.split(' ').map((value) => ({ label: authTitles[value], value }));
};

export function AuthModal() {
  const dispatch = useDispatch();
  const ref = useRef();
  const login = useSelector(fromStore.userLoginSelector);
  const userCode = useSelector(fromStore.userCodeSelector);
  const loader = useSelector(fromStore.authLoaderSelector);
  const step = useSelector(fromStore.stepAuthSelector);
  const settings = useSelector(settingsSelector);
  const error = useSelector(fromStore.errorAuthSelector);

  const defaultAuth = settings.defaultAuthSite ? { label: authTitles[settings.defaultAuthSite], value: settings.defaultAuthSite } : null;
  const [selectedAuth, setSelectedAuth] = useState(defaultAuth);
  const authValue = selectedAuth ? selectedAuth.value : undefined;
  const auths = getAuth(settings.variantAuthsSite);

  const onClose = () => dispatch(setActiveModalAction({ field: 'auth', value: false }));
  const onChangeLogin = (e) => dispatch(changeLogin(e.target.value));
  const onChangeCode = (e) => dispatch(changeUserCode(e.target.value));
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(authUser({ login, userCode, mode: authValue || 'sms' }));
  };

  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  useEffect(() => {
    if (auths.length === 1) {
      setSelectedAuth(auths[0]);
    }
  }, []);

  const renderError = (
    <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
      <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
          <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
          <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="currentColor" />
        </svg>
      </span>
      <div className="d-flex flex-column">
        <h4 className="mb-1 text-danger">Ошибка авторизации</h4>
        <span>Повторите операцию позднее</span>
      </div>
    </div>
  );

  const firstStep = (
    <div data-kt-element="sms">
      <h3 className="text-dark fw-bolder fs-3 mb-5">Введите свой номер телефона, чтобы войти</h3>
      { auths.length === 1 && <div className="fs-4 mb-10">{infoText[authValue]}</div> }
      <form onSubmit={onSubmit} className="form">
        <div className="fv-row">
          <InputMask mask="+7 (999) 999-99-99" value={login} onChange={onChangeLogin}>
            <input
              ref={ref}
              onChange={onChangeLogin}
              value={login}
              type="text"
              className="form-control form-control-lg form-control-solid"
              placeholder="Введите номер телефона..."
            />
          </InputMask>
        </div>

        {auths.length > 1 && (
          <div className="mt-10">
            <div className="d-flex align-items-center form-label mb-3">
              Способ авторизации
            </div>
            <Select
              value={isEmpty(selectedAuth) ? null : selectedAuth}
              className="react-select-container"
              classNamePrefix="react-select"
              options={auths}
              placeholder="Выберите способ авторизации"
              onChange={(item) => setSelectedAuth(item)}
              getOptionLabel={(item) => item.label}
              getOptionValue={(item) => item.value}
              isClearable={false}
              noOptionsMessage={() => 'Нет данных'}
            />
            <div className="text-muted fs-6 mb-10">{infoText[authValue]}</div>
          </div>
        )}

        <div className="border-dashed rounded p-7 mb-10 mt-10">
          <h3 className="text-dark fw-bolder mb-3">Бонусы SUSHIBOX</h3>
          <div className="text-gray-500 fw-bold fs-6 mb-5">
            Если у вас есть реферальный код друга, введите его в поле и получайте бонусы
          </div>
          <input
            value={userCode}
            onChange={onChangeCode}
            type="text"
            minLength={3}
            maxLength={10}
            className="form-control form-control-lg form-control-solid"
            placeholder="Введите реферальный код друга"
          />
        </div>
        {error && renderError}
        <div className="d-flex flex-center">
          <button onClick={onClose} type="button" className="btn btn-light me-3">Отмена</button>
          <button type="submit" disabled={!login || loader || !PHONE_REG_EXP.test(login)} className="btn btn-danger">
            {loader ? (
              <span>
                Обработка...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (<span className="indicator-label">Продолжить</span>)}
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <Modal title="Вход" onClose={onClose}>{step === '2' || step === '3' ? <ConfirmStep mode={authValue} /> : firstStep}</Modal>
  );
}
