import { setAuthError, logoutAction, setActiveModalAction } from '../../actions';
import { identityPayload, createReducer, forAction } from '../../utils';

const initialState = null;

export default createReducer(
  [
    forAction(setAuthError, identityPayload),
    forAction(setActiveModalAction, (state, { payload }) => {
      const { field, value } = payload;

      if (field === 'auth' && !value) {
        return initialState;
      }

      return state;
    }),
  ],
  initialState,
  [logoutAction],
);
