import {
  cancelOrder, clearCart, clearPromotion,
  setOrderDeliveryMethod,
  setPromotion,
  setSelectedCity,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = null;

export default createReducer(
  [forAction(setPromotion, identityPayload)],
  initialState,
  [cancelOrder, clearPromotion, setOrderDeliveryMethod, clearPromotion, setSelectedCity, clearCart],
);
