import React from 'react';
import '../css/Effects.css';
import { useSelector } from 'react-redux';
import { settingsSelector } from '../selectors';

function snowEffects() {
  const snowflakes = Array.from({ length: 300 }).map((_, index) => {
    const size = `${(Math.random() * 0.2 + 0.3).toFixed(2)}em`;
    const animationDuration = `${(Math.random() * (25 - 18) + 18).toFixed(2)}s`;
    const animationDelay = `${(Math.random() * 50).toFixed(2)}s`;
    const left = `${Math.random() * 100}%`;

    return (
      <div
        key={`snowflake-${index}`} // eslint-disable-line react/no-array-index-key
        style={{
          width: size,
          height: size,
          left,
          animationDuration,
          animationDelay,
        }}
        className="snowflake"
      />
    );
  });

  return (
    <>
      <div className="vetka left" />
      <div className="vetka right" />
      <div className="snowflakes">
        {snowflakes}
        <div className="snowdrift" />
      </div>
    </>
  );
}

function loveEffects() {
  const heartflakes = Array.from({ length: 130 }).map((_, index) => {
    const animationDuration = `${(Math.random() * (25 - 18) + 18).toFixed(2)}s`;
    const animationDelay = `${(Math.random() * 50).toFixed(2)}s`;
    const left = `${Math.random() * 100}%`;
    const opacity = (Math.random() * (1 - 0.7) + 0.7).toFixed(2);

    return (
      <div
        key={`heartflake-${index}`} // eslint-disable-line react/no-array-index-key
        style={{
          left,
          animationDuration,
          animationDelay,
          opacity,
        }}
        className="heartflake"
      />
    );
  });

  return (
    <>
      <div className="cupid left" />
      <div className="cupid right" />
      <div className="heartflakes">
        {heartflakes}
      </div>
    </>
  );
}

function Effects() {
  const settings = useSelector(settingsSelector);
  switch (settings.effects) {
    case 'snow': return snowEffects();
    case 'love': return loveEffects();
    default: return null;
  }
}

export default Effects;
