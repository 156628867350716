import {
  takeLatest, put, call, select, delay,
} from 'redux-saga/effects';
import {
  setCities,
  setSelectedCity,
  setActiveModalAction, loaderAction, initSuccessAction, authSuccess, setOrderDeliveryZoneData,
  fetchZone, fetchZones, setZones,
  clearOrderPricesPromocode,
} from '../actions';
import { restUrls } from '../constants';
import { fromStore } from '../selectors';
import { getCookie, request, setCookie } from '../utils';
import { updateCurrentUser } from './auth';

export function* fetchCitiesHandler() {
  const cities = yield call(request, { method: 'get', url: restUrls.cities });
  yield put(setCities(cities));
}

export function* fetchZoneHandler({ payload: id }) {
  yield put(loaderAction({ field: 'zone', value: true }));
  const zone = yield call(request, { method: 'get', url: `${restUrls.zone}/${id}` }, false);
  yield put(setOrderDeliveryZoneData(zone));
  yield delay(1000);
  yield put(loaderAction({ field: 'zone', value: false }));
}

export function* fetchZonesHandler({ payload: id }) {
  yield put(loaderAction({ field: 'zones', value: true }));
  const zones = yield call(request, { method: 'get', url: `${restUrls.zone}`, params: { city: id } });
  yield put(setZones(zones));
  yield put(loaderAction({ field: 'zones', value: false }));
}

function* onSetCityCookie({ payload: city }) {
  const user = yield select(fromStore.userSelector);
  if ((user && !user.city) || (user && user.city && user.city.id !== city.id)) {
    yield put(loaderAction({ field: 'city', value: true }));

    const updatedData = {
      privateHouse: false,
      city: city.id,
      area: null,
      street: null,
      house: null,
      room: null,
      entrance: null,
      floor: null,
      points: null,
      zone: null,
    };
    yield updateCurrentUser(updatedData);
  }
  const cityId = yield getCookie('city');
  if (+cityId !== city.id) {
    yield setCookie('city', city.id);
  }
  yield put(setActiveModalAction({ field: 'city', value: false }));
  yield put(clearOrderPricesPromocode());
  yield put(loaderAction({ field: 'city', value: false }));
}

function* initCity() {
  const user = yield select(fromStore.userSelector);
  const cities = yield select(fromStore.citiesListSelector);

  const cityCookieId = yield getCookie('city');
  const userCityId = user && user.city ? user.city.id : null;

  const cityId = userCityId || cityCookieId;

  if (!cityId) {
    yield put(setActiveModalAction({ field: 'city', value: true }));
  } else {
    const findedCity = cities.find((city) => city.id === +cityId);
    if (findedCity) {
      yield put(setSelectedCity(findedCity));
    } else {
      yield put(setActiveModalAction({ field: 'city', value: true }));
    }
  }
}

export default [
  takeLatest(setSelectedCity, onSetCityCookie),
  takeLatest(initSuccessAction, initCity),
  takeLatest(authSuccess, initCity),
  takeLatest(fetchZone, fetchZoneHandler),
  takeLatest(fetchZones, fetchZonesHandler),
];
