/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveModalAction, setOrderPhone, setOrderUnit, setOrderUserName,
} from '../../../actions';
import { PHONE_REG_EXP } from '../../../constants';
import { fromStore } from '../../../selectors';
import { formatPhone } from '../../../utils';

export function CustomerStep() {
  const dispatch = useDispatch();
  const user = useSelector(fromStore.userSelector);
  const phone = useSelector(fromStore.orderPhoneSelector);
  const orderUserName = useSelector(fromStore.orderCustomerNameSelector);
  const orderUnit = useSelector(fromStore.orderUnitSelector);
  const errors = useSelector(fromStore.orderErrorsSelector);

  const onChangePhone = (e) => dispatch(setOrderPhone(e.target.value));
  const onChangeUserName = (e) => dispatch(setOrderUserName(e.target.value));
  const onOpenAuth = () => dispatch(setActiveModalAction({ field: 'auth', value: true }));

  const handleAddUnit = () => {
    if (orderUnit < 20) {
      dispatch(setOrderUnit(orderUnit + 1));
    }
  };

  const handleDeleteUnit = () => {
    if (orderUnit > 1) {
      dispatch(setOrderUnit(orderUnit - 1));
    }
  };

  const onChange = () => {
    if (orderUnit !== null) {
      dispatch(setOrderUnit(null));
    } else {
      dispatch(setOrderUnit(1));
    }
  };

  useEffect(() => {
    if (user && !orderUserName) {
      dispatch(setOrderUserName(user.name));
    }
    if (user) {
      dispatch(setOrderPhone(formatPhone(user.phone)));
    }
  }, [user]);

  const renderUnit = (
    <div aria-hidden className="position-relative">
      <button onClick={handleDeleteUnit} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0">
        <span className="svg-icon svg-icon-2x">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
            <rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
      </button>
      <input type="text" className="form-control h-45px fs-3 text-center sb-counter-bottom form-control-solid border-0" readOnly="readonly" value={orderUnit === null ? 0 : orderUnit} />
      <button onClick={handleAddUnit} type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0">
        <span className="svg-icon svg-icon-2x">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor" />
            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
      </button>
    </div>
  );

  const renderPhone = () => (
    <div className="mb-7">
      <label className={cn('form-label fs-4 mb-3', { required: !user })}>Номер телефона</label>
      {user ? (
        <div className="mt-2 mb-10">
          <span className="fw-bolder fs-6 text-gray-800 me-3">{formatPhone(user.phone)}</span>
          <span className="badge badge-success">Подтвержден</span>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-stack">
            <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={onChangePhone}>
              <input type="text" className="form-control form-control-lg form-control-solid" placeholder="Введите номер телефона" value={phone} />
            </InputMask>
            {PHONE_REG_EXP.test(phone) && (
            <button onClick={onOpenAuth} type="button" className="btn btn-danger btn-hover-scale ms-5">Войти</button>
            )}
          </div>
          {errors.phone && <div className="invalid-feedback d-block">{errors.phone}</div>}
        </div>
      )}
    </div>
  );

  return (
    <div className="current">
      <div className="w-100">
        <div className="pb-10">
          <h2 className="fw-bolder text-dark ">Данные заказчика</h2>
        </div>
        {renderPhone()}
        <div className="mb-7">
          <label className="form-label mb-3 fs-4 required">Имя</label>
          <input
            type="text"
            onChange={onChangeUserName}
            className="form-control form-control-lg form-control-solid"
            placeholder="Введите имя"
            value={orderUserName}
          />
          {errors.username && <div className="invalid-feedback d-block">{errors.username}</div>}
        </div>

        <div className="mb-10">
          <label className="form-label mb-3 fs-4">Количество приборов</label>
          <div className="d-flex mb-3">{renderUnit}</div>
          {errors.unit && <div className="invalid-feedback d-block mb-5">{errors.unit}</div>}
          <label className="d-flex flex-stack mb-3 cursor-pointer">
            <span className="d-flex align-items-center me-2">
              <span className="symbol symbol-20px me-2">
                <span className="form-check form-check-custom form-check-solid">
                  <input onChange={onChange} className="form-check-input" type="checkbox" checked={orderUnit === null && true} />
                </span>
              </span>
              <span className="d-flex flex-column">
                <span className="fw-bold text-gray-800 fs-5">Без приборов</span>
              </span>
            </span>
          </label>
        </div>

      </div>
    </div>
  );
}
