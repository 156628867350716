import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { fromStore } from '../../selectors';
import {
  cancelOrder, createOrder, setOrderErrors, setOrderStep, setActiveModalAction,
} from '../../actions';
import { userSelector } from '../../selectors/auth';
import { PHONE_REG_EXP, EMAIL_REG_EXP } from '../../constants';
import { getTimeWork } from '../../utils';

export function Footer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const email = useSelector(fromStore.orderPaymentEmailSelector);
  const phone = useSelector(fromStore.orderPhoneSelector);
  const username = useSelector(fromStore.orderCustomerNameSelector);
  const activeStep = useSelector(fromStore.orderStepSelector);
  const deliveryMethod = useSelector(fromStore.orderDeliveryMethodSelector);
  const paymentMethod = useSelector(fromStore.orderPaymentMethodSelector);
  const zoneId = useSelector(fromStore.orderDeliveryZoneSelector);
  const zoneData = useSelector(fromStore.orderDeliveryZoneDataSelector);
  const adress = useSelector(fromStore.orderDeliveryAdressSelector);
  const point = useSelector(fromStore.orderDeliveryPointSelector);
  const adressLoader = useSelector(fromStore.promocodeLoaderSelector);
  const zoneLoader = useSelector(fromStore.zoneLoaderSelector);
  const promocodeLoader = useSelector(fromStore.promocodeLoaderSelector);
  const createOrderLoader = useSelector(fromStore.createOrderLoaderSelector);
  const timeMethod = useSelector(fromStore.orderDeliveryTimeMethodSelector);
  const selectedTime = useSelector(fromStore.orderDeliveryTimeSelector);
  const totalCost = useSelector(fromStore.totalPriceOrder);
  const orderUnit = useSelector(fromStore.orderUnitSelector);

  const disabled = promocodeLoader || zoneLoader || adressLoader;

  const checkCustomerStep = () => {
    const errors = {};
    if (!user && (!phone || !PHONE_REG_EXP.test(phone))) {
      if (!phone) {
        errors.phone = 'Обязательное поле';
      } else if (!PHONE_REG_EXP.test(phone)) {
        errors.phone = 'Введите корректный номер телефона';
      }
    }
    if (!username) errors.username = 'Обязательное поле';

    if (orderUnit === 0 && orderUnit !== null) errors.unit = 'Не указано кол-во приборов';

    if (!isEmpty(errors)) {
      dispatch(setOrderErrors(errors));
      return false;
    }
    dispatch(setOrderErrors({}));
    return true;
  };

  const checkDeliveryStep = () => {
    const errors = {};
    if (!deliveryMethod) errors.deliveryMethod = 'Необходимо выбрать способ доставки';
    if (deliveryMethod === 'delivery' && !adress.street) errors.adress = 'Необходимо добавить адрес доставки';
    if (deliveryMethod === 'own' && !point) errors.point = 'Необходимо выбрать пункт самовывоза';
    if (deliveryMethod === 'own' && point && (point.activate !== 'active' && point.activate !== 'disabledDelivery')) errors.pointActiveate = true;
    if (deliveryMethod === 'delivery' && zoneId === 'NOT_SUPPORTED_ADRESS') errors.zone = true;
    if (deliveryMethod === 'delivery' && zoneData && (zoneData.point.activate !== 'active' && zoneData.point.activate !== 'disabledOwn')) errors.pointActiveate = true;

    const timeWork = getTimeWork({
      delivery: deliveryMethod,
      timeMethod,
      selectedTime,
    });

    if (!timeWork.isWork) {
      return false;
    }

    if (!isEmpty(errors)) {
      dispatch(setOrderErrors(errors));
      return false;
    }
    dispatch(setOrderErrors({}));
    return true;
  };

  const checkPaymentStep = () => {
    /// <summary>
    /// Если оплата через терминал или наличными, тогда можно
    /// переходить к следующему этапу
    /// </summary>
    if (paymentMethod === 'terminal' || paymentMethod === 'cash') {
      return true;
    }

    /// <summary>
    /// Если оплата онлайн
    /// </summary>
    if (paymentMethod === 'online') {
      /// <summary>
      /// Если нет пользователя, то открываем окно авторизации и
      /// не пускаем к следующему этапу
      /// </summary>
      if (!user) {
        dispatch(setActiveModalAction({ field: 'auth', value: true }));
        return false;
      }

      /// <summary>
      /// Если есть почта и почта
      /// не прошла проверку на корректность, тогда
      /// не пускаем к следующему этапу
      /// </summary>
      if (email && !EMAIL_REG_EXP.test(email)) return false;

      /// <summary>
      /// Если способ доставки "забрать лично",
      /// есть данные о филиале, в данных о филиале заполнены поля onlinePay и yookassa, тогда можно
      /// переходить к следующему этапу
      /// </summary>
      if (deliveryMethod === 'own' && point && point.onlinePay && point.yookassa) {
        return true;
      }

      /// <summary>
      /// Если способ доставки "доставка на дом",
      /// есть данные о зоне доставки, в ней есть данные о филиале, в данных о филиале
      /// заполнены поля onlinePay и yookassa, тогда можно
      /// переходить к следующему этапу
      /// </summary>
      if (deliveryMethod === 'delivery' && zoneData && zoneData.point && zoneData.point.onlinePay && zoneData.point.yookassa) {
        return true;
      }
    }

    return false;
  };

  const isValidStep = () => {
    switch (activeStep) {
      case '1': return checkCustomerStep();
      case '2': return checkDeliveryStep();
      case '3': return checkPaymentStep();
      default: return true;
    }
  };

  const nextStepHandler = () => {
    if (!isValidStep()) return;
    const ymValue = ['order-contact', 'order-type', 'order-type-pay'];
    ym(92424510, 'reachGoal', ymValue[activeStep - 1]);
    dispatch(setOrderStep(String(+activeStep + 1)));
  };

  const backStepHandler = () => {
    dispatch(setOrderStep(String(+activeStep - 1)));
  };

  const cancelHandler = () => {
    navigate('/menu');
    dispatch(cancelOrder());
  };

  const onConfirm = () => {
    // eslint-disable-next-line no-underscore-dangle
    const _tmr = window._tmr || (window._tmr = []);
    _tmr.push({ type: 'reachGoal', id: 3367623, goal: 'finalbuy' });
    ym(92424510, 'reachGoal', 'order-finish');
    if (createOrderLoader) return;
    dispatch(createOrder(navigate));
  };

  const renderLeftButton = () => {
    if (activeStep !== '1') {
      return (
        <button disabled={disabled || createOrderLoader} onClick={backStepHandler} type="button" className="btn btn-lg btn-light-primary me-3">
          <span className="svg-icon svg-icon-4 me-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
              <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
            </svg>
          </span>
          Назад
        </button>
      );
    }
    if (activeStep === '1') {
      return (
        <button disabled={disabled} onClick={cancelHandler} type="button" className="btn btn-lg btn-light me-3">
          Отмена
        </button>
      );
    }

    return null;
  };

  const renderRightButton = () => {
    if (activeStep === '4') {
      return (
        <button disabled={totalCost === 0} onClick={onConfirm} type="button" className="btn btn-lg btn-danger me-3 btn-hover-scale">
          {!createOrderLoader ? (
            <span className="indicator-label">
              {paymentMethod === 'online' ? 'Оформить и оплатить заказ' : 'Оформить заказ'}
            </span>
          ) : (
            <span className="indicator-progress d-block">
              Оформление заказа...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      );
    }

    return (
      <button disabled={disabled} onClick={nextStepHandler} type="button" className="btn btn-lg btn-primary btn-hover-scale">
        Продолжить
        <span className="svg-icon svg-icon-4 ms-1 me-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
            <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
          </svg>
        </span>
      </button>
    );
  };

  return (
    <div className="d-flex flex-stack pt-10">
      <div className="mr-2">
        {renderLeftButton()}
      </div>
      <div>
        {renderRightButton()}
      </div>
    </div>
  );
}
