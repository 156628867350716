import { combineReducers } from 'redux';
import list from './list';
import active from './active';
import canClose from './canClose';
import gift from './gift';
import wokList from './wokList';
import date from './date';
import promo from './promo';

export default combineReducers({
  active,
  list,
  canClose,
  gift,
  wokList,
  date,
  promo,
});
