import dayjs from 'dayjs';
import { addToCart, clearCart } from '../../actions';
import { createReducer, forAction } from '../../utils';

const initialState = null;

const addToCartHandler = (state) => {
  if (state) return state;

  return dayjs().toISOString();
};

export default createReducer(
  [forAction(addToCart, addToCartHandler)],
  initialState,
  [clearCart],
);
