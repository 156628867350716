import {
  addToCart, cancelOrder, clearCart, deleteFromCart, setOrderPricesBonus,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = 0;

export default createReducer(
  [forAction(setOrderPricesBonus, identityPayload)],
  initialState,
  [cancelOrder, addToCart, deleteFromCart, clearCart],
);
