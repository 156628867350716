import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconTypes } from './Icon';
import { useOnClickOutside } from '../hooks';

export function EditableInput({
  value, defaultValue, onSave, loader, type = 'text',
}) {
  const inputWrapperRef = useRef();
  const inputRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  const onSetEditMode = () => {
    setEditMode(true);
  };

  const onSaveValue = (e) => {
    e.preventDefault();
    onSave(editedValue);
  };

  const onSetEditedValue = (e) => {
    setEditedValue(e.target.value);
  };

  useEffect(() => {
    if (editMode && inputRef) {
      inputRef.current.focus();
    }
  }, [inputRef, editMode]);

  useEffect(() => {
    if (value) {
      setEditMode(false);
    }
  }, [value]);

  useOnClickOutside(inputWrapperRef, () => {
    if (!loader) {
      setEditMode(false);
      setEditedValue(value);
    }
  });

  if (!editMode) {
    return (
      <div className="py-1">
        <span className="fw-bolder fs-6 text-gray-800">{value || defaultValue}</span>
        <button onClick={onSetEditMode} type="button" className="btn btn-sm btn-icon btn-white btn-active-white ms-1 btn-hover-scale">
          <Icon type={IconTypes.edit} className="svg-icon svg-icon-3" />
        </button>
      </div>
    );
  }

  const buttonText = loader ? (
    <>
      <div className="spinner-border text-light spinner-border-sm me-2" role="status" />
      Сохранение
    </>
  ) : (
    <>
      <Icon type={IconTypes.save} className="svg-icon svg-icon-1" />
      Сохранить
    </>
  );

  return (
    <form onSubmit={onSaveValue} ref={inputWrapperRef} className="d-flex flex-grow-1" key="save">
      <input
        ref={inputRef}
        type={type}
        className="form-control form-control-md form-control-solid mb-3 mb-lg-0"
        placeholder="Введите ваше имя"
        value={editedValue}
        maxLength={50}
        onChange={onSetEditedValue}
        disabled={loader}
      />
      <button type="submit" disabled={!editedValue || editedValue === value || loader} className="d-flex btn btn-danger btn-sm ms-1 btn-hover-scale flex-stack ms-3">
        {buttonText}
      </button>
    </form>

  );
}

EditableInput.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
