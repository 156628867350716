import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearOrderPricesPromocode, fetchOrderPricesPromocode, setOrderPricesPromocode } from '../../../../actions';
import { Icon, Input, IconTypes } from '../../../../components';
import { fromStore } from '../../../../selectors';

export function Promocode() {
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.promocodeLoaderSelector);
  const promocode = useSelector(fromStore.orderPricesPromocodeSelector);
  const isActive = useSelector(fromStore.isActivePromocodeSelector);
  const error = useSelector(fromStore.errorPromocodeSelector);
  const promocodeData = useSelector(fromStore.orderPricesPromocodeDataSelector);
  const createLoader = useSelector(fromStore.createOrderLoaderSelector);
  const firstOrderSale = useSelector(fromStore.saleFirstOrderSelector);
  const selectedPromotion = useSelector(fromStore.promotionSelector);

  const onChangePromocode = (value) => {
    dispatch(setOrderPricesPromocode(value));
  };

  const onSubmit = () => {
    dispatch(fetchOrderPricesPromocode(promocode));
  };

  const onReset = () => {
    dispatch(clearOrderPricesPromocode());
  };

  const renderButton = () => {
    if (isActive) {
      return (
        <button disabled={createLoader} onClick={onReset} type="button" className="btn btn-icon btn-light-danger ms-3">
          <Icon className="svg-icon svg-icon-2x" title="Сбросить промокод" type={IconTypes.close} />
        </button>
      );
    }
    return (
      <button
        onClick={onSubmit}
        disabled={!promocode || loader || createLoader || firstOrderSale > 0}
        type="button"
        className="btn btn-dark ms-3"
      >
        {loader ? (
          <>
            <div className="spinner-border text-light spinner-border-sm me-2" role="status" />
            Применение...
          </>
        ) : 'Применить'}
      </button>
    );
  };

  const renderPromocodeInfo = () => {
    if (!isActive) return null;
    if (promocodeData.type === 'order') {
      return (
        <span className="badge badge-info badge-sm ms-2 mb-2">
          {`Скидка на заказ ${promocodeData.value}${promocodeData.persent ? '%' : ' ₽'}`}
        </span>
      );
    }

    if (promocodeData.type === 'category') {
      return (
        <span className="badge badge-info badge-sm ms-2 mb-2">
          {`Категория ${promocodeData.category.label} -${promocodeData.value}${promocodeData.persent ? '%' : ' ₽'}`}
        </span>
      );
    }

    if (promocodeData.type === 'goods') {
      return Object.entries(promocodeData.goods).map(([key, value]) => (
        <span key={key} className="badge badge-info badge-sm ms-2 mb-2">
          {`${value.label} -${value.sale}${value.persent ? '%' : ' ₽'}`}
        </span>
      ));
    }

    return null;
  };

  return (
    <div className="mb-7 border px-7 rounded py-7">
      <h4 className="mb-3 d-flex align-items-center flex-wrap">
        <span className="mb-2">Промокод</span>
        {isActive && (
          <span className="badge badge-success badge-sm ms-2 mb-2">Активен</span>
        )}
        {renderPromocodeInfo()}
      </h4>
      <div className="d-flex align-items-start">
        <Input
          disabled={loader || createLoader || firstOrderSale > 0 || selectedPromotion}
          readonly={isActive}
          value={promocode}
          onChange={onChangePromocode}
          maxLength={25}
          className="p-0"
          placeholder="Введите промокод"
        />
        {renderButton()}
      </div>
      {error && <div className="invalid-feedback d-block mt-3">{error}</div>}
    </div>
  );
}
