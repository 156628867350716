import {
  cancelOrder, setOrderPricesPromocodeData, fetchOrderPricesPromocode, clearOrderPricesPromocode,
  setOrderDeliveryMethod,
  clearCart,
} from '../../../actions';
import { identityPayload, createReducer, forAction } from '../../../utils';

const initialState = null;

export default createReducer(
  [forAction(setOrderPricesPromocodeData, identityPayload)],
  initialState,
  [cancelOrder, fetchOrderPricesPromocode, clearOrderPricesPromocode, setOrderDeliveryMethod, clearCart],
);
