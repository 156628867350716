export const storeSelector = (state) => state;

export const citiesSelector = (state) => state.cities;

export const settingsSelector = (state) => state.settings;

export const loadersSelector = (state) => state.loaders;

export const authSelector = (state) => state.auth;

export const modalsSelector = (state) => state.modals;

export const goodsSelector = (state) => state.goods;

export const cartSelector = (state) => state.cart;

export const entitesSelector = (state) => state.entities;

export const orderSelector = (state) => state.order;

export const promotionsSelector = (state) => state.promotions;

export const newsSelector = (state) => state.news;

export const slidersSelector = (state) => state.sliders;

export const notificationsSelector = (state) => state.notifications;

export const isSuccessFeedbackSelector = (state) => state.feedback;

export const asideSelector = (state) => state.aside;
